export const useNavigation = () => {
    const navigateToUrl = (url, shouldReplace = false) => {
        if (shouldReplace) {
            history.replaceState({}, "", url);
        } else {
            history.pushState({}, "", url);
        }
        history.go();
    };

    const navigateBack = () => {
        history.back();
    };

    const getCurrentPath = () => window.location.pathname;

    const isOnPageWithPathPart = (pathPart) => {
        return window.location.pathname.includes(pathPart);
    }

    const reloadPage = () => {
        window.location.reload();
    }

    return {
        navigateToUrl,
        navigateBack,
        getCurrentPath,
        isOnPageWithPathPart,
        reloadPage
    };
};

export function getReducedWorkflowHistory(record) {
    const data = record?.externalWorkflow?.history || [];

    if (data.length > 0) {
        const sortedData = data.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            if (a.workflowHandle === b.workflowHandle) {
                return dateB - dateA;
            }
            return a.workflowHandle.localeCompare(b.workflowHandle);
        });

        const groupedData = sortedData.reduce((acc, item) => {
            if (!acc[item.workflowHandle]) {
                acc[item.workflowHandle] = item;
            }
            return acc;
        }, {});

        const newestData = Object.values(groupedData).sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });

        return newestData;
    }

    return null;
}
