import React from 'react';
import PropTypes from "prop-types";
import {Button} from "semantic-ui-react";
import {useNavigation} from "../../../tools";

export const EditButton = ({draftId, disabled}) => {
    const editUrl = `/lter/${draftId}/edit`
    const navigate = useNavigation();

    const handleClick = (e) => {
        e.preventDefault();
        navigate.navigateToUrl(editUrl);
    }

    return (
        <Button color="orange" fluid onClick={handleClick} disabled={disabled ?? false}>
            Edit
        </Button>
    );
};

EditButton.propTypes = {
    draftId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};
